


































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { cloneDeep, first, fromPairs, groupBy, isArray, sortBy, toPairs } from 'lodash';
import { PieChart, BarChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import CrmAppBar from '@/components/crm/app-bar.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { UserTypeEnum } from '@/enums/crm/user-type.enum';
import DataGridFilter from '@/components/data-grid-filter.vue';
import DateRangeFilter from '@/components/date-range-filter.vue';
import dayjs from '@/plugins/dayjs';
import SettingsModel from '@/models/crm/settings.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import RepresentativeModel from '@/models/crm/representative.model';
import CityModel from '@/models/city.model';
import StateModel from '@/models/state.model';
import SettingsService from '@/services/crm/settings.service';
import ContactService from '@/services/crm/contact.service';
import LocalizationService from '@/services/localization.service';
import ActivityService from '@/services/crm/activity.service';
import UserModel from '@/models/user.model';
import RepresentativeService from '@/services/crm/representative.service';
import StatisticsService from '@/services/crm/statistics.service';
import { StatisticsVisualizationEnum } from '@/enums/crm/statistics-visualization.enum';
import { IStatisticsFilter } from '@/interfaces/crm/statistics-filter.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import StatisticsPerCity from '@/models/crm/statistics-per-city.model';
import StatisticsPerAttendant from '@/models/crm/statistics-per-attendant.model';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import { DateHelper } from '@/utils/helpers/date-helper';
import StatisticsPerRepresentative from '@/models/crm/statistics-per-representative.model';
import StatisticsPerCollection from '@/models/crm/statistics-per-collection.model';
import DynamicFieldModel from '@/models/dynamic-field.model';
import OrderService from '@/services/crm/order.service';

Chart.register(...registerables);

type DataGridFilterConfig = {
  period: (Date | undefined)[];
  attendant: number[] | null;
  representative: RepresentativeModel[] | null;
  state: StateModel | null;
  city: CityModel[] | null;
  integratorUser: string[] | null;
  orderOrigin: string[] | null;
};

interface IChartSettings {
  key: string;
  chartData: ChartData | null;
  loading: boolean;
  summaryValue: number;
  summary?: IKeyValue<IChartSettingsSummary>;
  inError?: boolean;
}

interface IChartSettingsSummary {
  label: string;
  round?: boolean; // Default: true
  format?: string; // Default: numeral
}

interface ICardDetails {
  key: string;
  unit: string;
  value: number;
  loading: boolean;
  secondaryValue?: number;
  secondaryPrefix?: string;
  secondarySuffix?: string;
  detailsCallback?: CallableFunction;
}

interface ICalcableStatistics {
  total: number;
  valor: number;
}

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    PieChart,
    BarChart,
    CrmAppBar,
  },
})
export default class CrmStatistics extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.LocalizationService)
  private localizationService!: LocalizationService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmRepresentativeService)
  private representativeService!: RepresentativeService;

  @inject(InjectionIdEnum.CrmStatisticsService)
  private statisticsService!: StatisticsService;

  @inject(InjectionIdEnum.CrmOrderService)
  private orderService!: OrderService;

  settings: SettingsModel | null = null;

  userContactInfo: UserContactInfo | null = null;

  multipleFilterChanged = false;

  visualization: StatisticsVisualizationEnum = StatisticsVisualizationEnum.Quantity;

  considerUserWhoEnterOrder = false;

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        maxHeight: 208,
        labels: {
          boxWidth: 12,
          padding: 5,
        },
      },
    },
  };

  cardDetails: ICardDetails[] = [
    {
      key: 'conversion',
      unit: this.$t('crm.view.statistics.abbreviations.client').toString(),
      value: 0,
      loading: false,
      secondaryValue: 0,
      secondarySuffix: this.$t('crm.view.statistics.abbreviations.percentage').toString(),
    },
    {
      key: 'averageTicket',
      unit: this.$t('crm.view.statistics.abbreviations.piece').toString(),
      value: 0,
      loading: false,
    },
    {
      key: 'averageAttendanceTime',
      unit: this.$t('crm.view.statistics.abbreviations.minute').toString(),
      value: 0,
      loading: false,
    },
  ];

  mainChart: IChartSettings = CrmStatistics.generateChartSettings('contactedClientsPerState');

  pieCharts: IChartSettings[] = [
    CrmStatistics.generateChartSettings('contactedClientsPerState', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
    }),
    CrmStatistics.generateChartSettings('contactedClientsPerCity', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
    }),
    CrmStatistics.generateChartSettings('contactedClientsPerAttendant', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
    }),

    CrmStatistics.generateChartSettings('convertedClientsPerState', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('convertedClientsPerCity', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('convertedClientsPerAttendant', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),

    CrmStatistics.generateChartSettings('openingOfNewClientsPerState', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
    }),
    CrmStatistics.generateChartSettings('openingOfNewClientsPerCity', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
    }),
    CrmStatistics.generateChartSettings('openingOfNewClientsPerAttendant', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalClients',
      },
    }),

    CrmStatistics.generateChartSettings('averageTicketPerState', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalPieces',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('averageTicketPerCity', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalPieces',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('averageTicketPerRepresentative', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalPieces',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),

    CrmStatistics.generateChartSettings('averageDiscountPerState', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averagePercent',
        round: false,
        format: 'decimal',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('averageDiscountPerCity', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averagePercent',
        round: false,
        format: 'decimal',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('averageDiscountPerRepresentative', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averagePercent',
        round: false,
        format: 'decimal',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),

    CrmStatistics.generateChartSettings('ordersPerRepresentative', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalOrders',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),
    CrmStatistics.generateChartSettings('ordersPerCollection', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.totalOrders',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.totalCurrencyReal',
        round: false,
        format: 'decimal',
      },
    }),

    CrmStatistics.generateChartSettings('averagePaymentTermPerState', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averageDays',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.averageDays',
      },
    }),
    CrmStatistics.generateChartSettings('averagePaymentTermPerCity', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averageDays',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.averageDays',
      },
    }),
    CrmStatistics.generateChartSettings('averagePaymentTermPerRepresentative', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averageDays',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.averageDays',
      },
    }),

    CrmStatistics.generateChartSettings('averageAttendanceTimePerAttendant', {
      [StatisticsVisualizationEnum.Quantity]: {
        label: 'crm.view.statistics.labels.averageMinutes',
      },
      [StatisticsVisualizationEnum.Value]: {
        label: 'crm.view.statistics.labels.averageMinutes',
      },
    }),
  ];

  // #region filters

  filterOpen = 1;

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  filters: DataGridFilterConfig = {
    period: [dayjs().startOf('month').toDate(), dayjs().toDate()],
    attendant: null,
    representative: null,
    state: null,
    city: null,
    integratorUser: null,
    orderOrigin: null,
  };

  attendantOptions: UserModel[] = [];

  representativeOptions: RepresentativeModel[] = [];

  stateOptions: StateModel[] = [];

  cityOptions: CityModel[] = [];

  integratorUserOptions: DynamicFieldModel[] = [];

  orderOriginOptions: DynamicFieldModel[] = [];

  cityFilterLoading = false;

  // #endregion

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.settings = await this.settingsService.getSettings();
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();

      await this.loadFilterOptions();

      this.onFilterChanged();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onFilterChanged(): Promise<void> {
    if (this.settings?.loggedUserType === UserTypeEnum.Normal && this.userContactInfo) {
      this.filters.attendant = [this.userContactInfo.id];
    } else if (!this.filters.attendant?.length && this.settings?.loggedUserType
      === UserTypeEnum.Manager) {
      this.filters.attendant = this.attendantOptions.map((x) => x.id);
    }
    this.multipleFilterChanged = false;

    const filter = this.parseFilter();
    let tasks: Promise<void[]>[] = [];

    tasks = [
      this.loadContactedVsConvertedClientCharts(filter),
      this.loadContactedClientCharts(filter),
      this.loadConvertedClientCharts(filter),
      this.loadOpeningOfNewClientCharts(filter),
      this.loadAverageTicketCharts(filter),
      this.loadOrdersCharts(filter),
      this.loadAveragePaymentTermCharts(filter),
      this.loadAverageAttendanceTimeCharts(filter),
      this.loadAverageDiscountCharts(filter),
    ];

    try {
      await Promise.all(tasks);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  onPeriodFilterChange(): void {
    this.onFilterChanged();
  }

  onAttendantFilterChange(): void {
    if (!this.multipleFilterChanged) {
      return;
    }

    this.onFilterChanged();
  }

  onAttendantFilterClear(): void {
    this.onFilterChanged();
  }

  onIntegratorUserFilterChange(): void {
    if (!this.multipleFilterChanged) {
      return;
    }

    this.onFilterChanged();
  }

  onIntegratorUserFilterClear(): void {
    this.onFilterChanged();
  }

  onOrderOriginFilterChange(): void {
    if (!this.multipleFilterChanged) {
      return;
    }

    this.onFilterChanged();
  }

  onOrderOriginFilterClear(): void {
    this.onFilterChanged();
  }

  onRepresentativeFilterChange(): void {
    if (!this.multipleFilterChanged) {
      return;
    }

    this.onFilterChanged();
  }

  onRepresentativeFilterClear(): void {
    this.onFilterChanged();
  }

  async onStateFilterChange(state: StateModel): Promise<void> {
    this.cityOptions = [];
    this.filters.city = null;

    this.onFilterChanged();

    if (!state?.sigla) {
      return;
    }

    this.cityOptions = await this.getCities(state.sigla);
  }

  onCityFilterChange(): void {
    if (!this.multipleFilterChanged) {
      return;
    }

    this.onFilterChanged();
  }

  onCityFilterClear(): void {
    this.onFilterChanged();
  }

  onVisualizationChange(): void {
    this.onFilterChanged();
  }

  onConsiderUserWhoEnterOrderChange(): void {
    this.onFilterChanged();
  }

  get mainChartTitle(): string {
    if (this.visualization === StatisticsVisualizationEnum.Value) {
      return this.$t('crm.view.statistics.labels.conversion').toString();
    }

    return this.$t('crm.view.statistics.labels.clientsContactedVsConverted').toString();
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore: string[] = [];
    const filterKeys = Object.keys(this.filters);

    if (this.userContactInfo !== null && !this.notNormalUser) {
      filtersToIgnore.push('attendant');
    }

    filterKeys.forEach((key) => {
      if (key === 'period') {
        const validDates = this.filters[key].filter((date) => date && !Number.isNaN(new Date(date).getTime()));
        if (validDates.length === 0) {
          return;
        }
      }
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && this.filters[key] && !(isArray(this.filters[key])
            && !this.filters[key].length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get isVisualizationValue(): boolean {
    return this.visualization === StatisticsVisualizationEnum.Value;
  }

  get notNormalUser(): boolean {
    return this.settings?.loggedUserType !== UserTypeEnum.Normal;
  }

  // #region Main chart

  private async loadContactedVsConvertedClientCharts(filter: IStatisticsFilter): Promise<void[]> {
    const tasks: Promise<void>[] = [];

    if (filter.visualization === StatisticsVisualizationEnum.Value) {
      tasks.push(this.loadConvertedClientChart(filter));
    } else {
      tasks.push(this.loadContactedVersusConvertedChart(filter));
    }

    return Promise.all(tasks);
  }

  private async loadContactedVersusConvertedChart(filter: IStatisticsFilter): Promise<void> {
    const conversionCardDetails = this.getCardDetailsSettings('conversion');

    this.mainChart.loading = true;
    conversionCardDetails.loading = true;

    try {
      const tasks = [
        this.statisticsService.getContactedClientsPerDay(filter),
        this.statisticsService.getConvertedClientsPerDay(filter),
      ];
      await Promise.all(tasks);

      const contactedClientsData = await tasks[0];
      const convertedClientsData = await tasks[1];

      const labels: string[] = [];
      const contactedClientsTotals: number[] = [];
      const convertedClientsTotals: number[] = [];

      const startPeriod = dayjs(filter.periodStart);
      const diff = dayjs(filter.periodEnd).diff(dayjs(filter.periodStart), 'day');

      for (let i = 0; i <= diff; i += 1) {
        const date = startPeriod.add(i, 'day');
        labels.push(date.format('DD/MM/YYYY'));

        const contactedClient = contactedClientsData.find((x) => x.data === date.format('YYYY-MM-DD'));
        contactedClientsTotals.push(contactedClient?.valor || 0);

        const convertedClient = convertedClientsData.find((x) => x.data === date.format('YYYY-MM-DD'));
        convertedClientsTotals.push(convertedClient?.valor || 0);
      }

      this.mainChart.chartData = {
        labels,
        datasets: [
          {
            label: this.$t('crm.view.statistics.labels.contacted').toString(),
            data: contactedClientsTotals,
            backgroundColor: StatisticsService.getChartBackgroundColor(true)[0],
          },
          {
            label: this.$t('crm.view.statistics.labels.converted').toString(),
            data: convertedClientsTotals,
            backgroundColor: StatisticsService.getChartBackgroundColor(true)[1],
          },
        ],
      };

      const convertedTotal = convertedClientsTotals.reduce((acc, item) => acc + item, 0);
      const contactedTotal = contactedClientsTotals.reduce((acc, item) => acc + item, 0);
      conversionCardDetails.value = convertedTotal;
      conversionCardDetails.secondaryValue = convertedTotal ? Math.round((convertedTotal / contactedTotal) * 100) : 0;

      conversionCardDetails.unit = this.$t('crm.view.statistics.abbreviations.client').toString();
      conversionCardDetails.secondarySuffix = this.$t('crm.view.statistics.abbreviations.percentage').toString();
      conversionCardDetails.secondaryPrefix = undefined;

      this.mainChart.inError = false;
    } catch (error) {
      conversionCardDetails.value = 0;
      this.mainChart.inError = true;
    } finally {
      this.mainChart.loading = false;
      conversionCardDetails.loading = false;
    }
  }

  private async loadConvertedClientChart(filter: IStatisticsFilter): Promise<void> {
    const quantityFilter = cloneDeep(filter);
    quantityFilter.visualization = StatisticsVisualizationEnum.Quantity;

    const conversionCardDetails = this.getCardDetailsSettings('conversion');

    this.mainChart.loading = true;
    conversionCardDetails.loading = true;

    try {
      const convertedClientsData = await this.statisticsService.getConvertedClientsPerDay(filter);

      const labels: string[] = [];
      const convertedClientsTotals: number[] = [];

      const startPeriod = dayjs(filter.periodStart);
      const diff = dayjs(filter.periodEnd).diff(dayjs(filter.periodStart), 'day');

      for (let i = 0; i <= diff; i += 1) {
        const date = startPeriod.add(i, 'day');
        labels.push(date.format('DD/MM/YYYY'));

        const convertedClient = convertedClientsData.find((x) => x.data === date.format('YYYY-MM-DD'));
        convertedClientsTotals.push(convertedClient?.valor || 0);
      }

      this.mainChart.chartData = {
        labels,
        datasets: [
          {
            label: this.$t('crm.view.statistics.labels.conversion').toString(),
            data: convertedClientsTotals,
            backgroundColor: StatisticsService.getChartBackgroundColor(true)[0],
          },
        ],
      };

      const convertedTotal = convertedClientsData.reduce((acc, item) => acc + item.total, 0);
      const totalClients = convertedClientsData.reduce((acc, item) => acc + item.total2, 0);
      conversionCardDetails.value = convertedTotal;
      conversionCardDetails.secondaryValue = convertedTotal ? convertedTotal / totalClients : 0;

      conversionCardDetails.unit = this.$t('crm.view.statistics.abbreviations.currencyReal').toString();
      conversionCardDetails.secondarySuffix = undefined;
      conversionCardDetails.secondaryPrefix = this.$t('crm.view.statistics.abbreviations.currencyReal').toString();

      this.mainChart.inError = false;
    } catch (error) {
      conversionCardDetails.value = 0;
      this.mainChart.inError = true;
    } finally {
      this.mainChart.loading = false;
      conversionCardDetails.loading = false;
    }
  }

  // #endregion

  // #region Contacted Client Charts

  private async loadContactedClientCharts(filter: IStatisticsFilter): Promise<void[]> {
    const chartFilter = cloneDeep(filter);
    chartFilter.visualization = StatisticsVisualizationEnum.Quantity;

    const tasks = [
      this.loadContactedClientPerAttedantChart(chartFilter),
      this.loadContactedClientPerCityAndStateCharts(chartFilter),
    ];

    return Promise.all(tasks);
  }

  private async loadContactedClientPerAttedantChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('contactedClientsPerAttendant');

    await this.loadChard(
      settings,
      CrmStatistics.populatePerAttendantChart,
      CrmStatistics.calculateTotal,
      this.statisticsService.getContactedClientsPerAttendant(filter),
    );
  }

  private async loadContactedClientPerCityAndStateCharts(filter: IStatisticsFilter): Promise<void> {
    const perCitySettings = this.getChartSettings('contactedClientsPerCity');
    const perStateSettings = this.getChartSettings('contactedClientsPerState');

    const source = this.statisticsService.getContactedClientsPerCity(filter);

    await Promise.all([
      this.loadChard(perCitySettings, CrmStatistics.populatePerCityChart, CrmStatistics.calculateTotal, source),
      this.loadChard(perStateSettings, CrmStatistics.populatePerStateChart, CrmStatistics.calculateTotal, source),
    ]);
  }

  // #endregion

  // #region Converted clients Charts

  private async loadConvertedClientCharts(filter: IStatisticsFilter): Promise<void[]> {
    const tasks = [
      this.loadConvertedClientPerAttendantChart(filter),
      this.loadConvertedClientPerCityAndStateCharts(filter),
    ];

    return Promise.all(tasks);
  }

  private async loadConvertedClientPerAttendantChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('convertedClientsPerAttendant');
    const source = this.statisticsService.getConvertedClientsPerAttendant(filter);
    await this.loadChard(settings, CrmStatistics.populatePerAttendantChart, CrmStatistics.calculateTotal, source);
  }

  private async loadConvertedClientPerCityAndStateCharts(filter: IStatisticsFilter): Promise<void> {
    const perCitySettings = this.getChartSettings('convertedClientsPerCity');
    const perStateSettings = this.getChartSettings('convertedClientsPerState');

    const source = this.statisticsService.getConvertedClientsPerCity(filter);

    await Promise.all([
      this.loadChard(perCitySettings, CrmStatistics.populatePerCityChart, CrmStatistics.calculateTotal, source),
      this.loadChard(perStateSettings, CrmStatistics.populatePerStateChart, CrmStatistics.calculateTotal, source),
    ]);
  }

  // #endregion

  // #region Opening of new clients Charts

  private async loadOpeningOfNewClientCharts(filter: IStatisticsFilter): Promise<void[]> {
    const chartFilter = cloneDeep(filter);
    chartFilter.visualization = StatisticsVisualizationEnum.Quantity;

    const tasks = [
      this.loadOpeningOfNewClientPerAttendantChart(chartFilter),
      this.loadOpeningOfNewClientPerCityAndStateCharts(chartFilter),
    ];

    return Promise.all(tasks);
  }

  private async loadOpeningOfNewClientPerAttendantChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('openingOfNewClientsPerAttendant');
    const source = this.statisticsService.getOpeningOfNewClientsPerAttendant(filter);
    await this.loadChard(settings, CrmStatistics.populatePerAttendantChart, CrmStatistics.calculateTotal, source);
  }

  private async loadOpeningOfNewClientPerCityAndStateCharts(filter: IStatisticsFilter): Promise<void> {
    const perCitySettings = this.getChartSettings('openingOfNewClientsPerCity');
    const perStateSettings = this.getChartSettings('openingOfNewClientsPerState');

    const source = this.statisticsService.getOpeningOfNewClientsPerCity(filter);

    await Promise.all([
      this.loadChard(perCitySettings, CrmStatistics.populatePerCityChart, CrmStatistics.calculateTotal, source),
      this.loadChard(perStateSettings, CrmStatistics.populatePerStateChart, CrmStatistics.calculateTotal, source),
    ]);
  }

  // #endregion

  // #region Average ticket Charts

  private async loadAverageTicketCharts(filter: IStatisticsFilter): Promise<void[]> {
    const tasks = [
      this.loadAverageTicketPerRepresentativeChart(filter),
      this.loadAverageTicketPerCityAndStateCharts(filter),
    ];

    return Promise.all(tasks);
  }

  private async loadAverageTicketPerRepresentativeChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('averageTicketPerRepresentative');

    await this.loadChard(
      settings,
      CrmStatistics.populatePerRepresentativeChart,
      CrmStatistics.calculateTotal,
      this.statisticsService.getAverageTicketPerRepresentative(filter),
    );
  }

  private async loadAverageTicketPerCityAndStateCharts(filter: IStatisticsFilter): Promise<void> {
    const perCitySettings = this.getChartSettings('averageTicketPerCity');
    const perStateSettings = this.getChartSettings('averageTicketPerState');
    const averageTicketCardDetails = this.getCardDetailsSettings('averageTicket');

    averageTicketCardDetails.unit = this.isVisualizationValue
      ? this.$t('crm.view.statistics.abbreviations.currencyReal').toString()
      : this.$t('crm.view.statistics.abbreviations.piece').toString();

    averageTicketCardDetails.loading = true;
    try {
      const source = this.statisticsService.getAverageTicketPerCity(filter);

      await Promise.all([
        this.loadChard(perCitySettings, CrmStatistics.populatePerCityChart, CrmStatistics.calculateTotal, source),
        this.loadChard(perStateSettings, CrmStatistics.populatePerStateChart, CrmStatistics.calculateTotal, source),
      ]);

      const data = await source;
      const orderQuantity = data.reduce((acc, item) => acc + item.total2, 0);
      const result = orderQuantity ? perCitySettings.summaryValue / orderQuantity : 0;
      averageTicketCardDetails.value = !this.isVisualizationValue ? Math.round(result) : result;
    } catch (error) {
      averageTicketCardDetails.value = 0;
      throw error;
    } finally {
      averageTicketCardDetails.loading = false;
    }
  }

  // #endregion

  // #region Average discount Charts

  private async loadAverageDiscountCharts(filter: IStatisticsFilter): Promise<void[]> {
    const tasks = [
      this.loadAverageDiscountPerRepresentativeChart(filter),
      this.loadAverageDiscountPerCityAndStateCharts(filter),
    ];

    return Promise.all(tasks);
  }

  private async loadAverageDiscountPerRepresentativeChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('averageDiscountPerRepresentative');

    const iQuantityVisualization = filter.visualization === StatisticsVisualizationEnum.Quantity;
    const totalCallback = iQuantityVisualization ? CrmStatistics.calculateValueAverage : CrmStatistics.calculateTotal;

    await this.loadChard(
      settings,
      CrmStatistics.populatePerRepresentativeChart,
      totalCallback,
      this.statisticsService.getAverageDiscountPerRepresentative(filter),
    );
  }

  private async loadAverageDiscountPerCityAndStateCharts(filter: IStatisticsFilter): Promise<void> {
    const perCitySettings = this.getChartSettings('averageDiscountPerCity');
    const perStateSettings = this.getChartSettings('averageDiscountPerState');

    const source = this.statisticsService.getAverageDiscountPerCity(filter);

    const iQuantityVisualization = filter.visualization === StatisticsVisualizationEnum.Quantity;
    const totalCallback = iQuantityVisualization ? CrmStatistics.calculateValueAverage : CrmStatistics.calculateTotal;

    await Promise.all([
      this.loadChard(perCitySettings, CrmStatistics.populatePerCityChart, totalCallback, source),
      this.loadChard(perStateSettings, CrmStatistics.populatePerStateChart, totalCallback, source),
    ]);
  }

  // #endregion

  // #region Average payment term Charts

  private async loadAveragePaymentTermCharts(filter: IStatisticsFilter): Promise<void[]> {
    const chartFilter = cloneDeep(filter);
    chartFilter.visualization = StatisticsVisualizationEnum.Quantity;

    const tasks = [
      this.loadAveragePaymentTermPerRepresentativeChart(chartFilter),
      this.loadAveragePaymentTermPerCityAndStateCharts(chartFilter),
    ];

    return Promise.all(tasks);
  }

  private async loadAveragePaymentTermPerRepresentativeChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('averagePaymentTermPerRepresentative');

    await this.loadChard(
      settings,
      CrmStatistics.populatePerRepresentativeChart,
      CrmStatistics.calculateTotalAverage,
      this.statisticsService.getAveragePaymentTermPerRepresentative(filter),
    );
  }

  private async loadAveragePaymentTermPerCityAndStateCharts(filter: IStatisticsFilter): Promise<void> {
    const perCitySettings = this.getChartSettings('averagePaymentTermPerCity');
    const perStateSettings = this.getChartSettings('averagePaymentTermPerState');

    const source = this.statisticsService.getAveragePaymentTermPerCity(filter);

    await Promise.all([
      this.loadChard(perCitySettings, CrmStatistics.populatePerCityChart, CrmStatistics.calculateTotalAverage, source),
      this.loadChard(
        perStateSettings,
        CrmStatistics.populatePerStateChart,
        CrmStatistics.calculateTotalAverage,
        source,
      ),
    ]);
  }

  // #endregion

  // #region Average attendance time Charts

  private async loadAverageAttendanceTimeCharts(filter: IStatisticsFilter): Promise<void[]> {
    const chartFilter = cloneDeep(filter);
    chartFilter.visualization = StatisticsVisualizationEnum.Quantity;

    const tasks = [this.loadAverageAttendanceTimePerAttendantChart(chartFilter)];

    return Promise.all(tasks);
  }

  private async loadAverageAttendanceTimePerAttendantChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('averageAttendanceTimePerAttendant');
    const averageAttendanceTimeCardDetails = this.getCardDetailsSettings('averageAttendanceTime');

    averageAttendanceTimeCardDetails.loading = true;
    try {
      await this.loadChard(
        settings,
        CrmStatistics.populatePerAttendantChart,
        CrmStatistics.calculateTotalAverage,
        this.statisticsService.getAverageAttendanceTimePerAttendant(filter),
      );

      averageAttendanceTimeCardDetails.value = settings.summaryValue;
    } catch (error) {
      averageAttendanceTimeCardDetails.value = 0;
      throw error;
    } finally {
      averageAttendanceTimeCardDetails.loading = false;
    }
  }

  // #endregion

  // #region Orders Charts

  private async loadOrdersCharts(filter: IStatisticsFilter): Promise<void[]> {
    const tasks = [this.loadOrdersPerRepresentativeChart(filter), this.loadOrdersPerCollectionChart(filter)];

    return Promise.all(tasks);
  }

  private async loadOrdersPerRepresentativeChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('ordersPerRepresentative');

    await this.loadChard(
      settings,
      CrmStatistics.populatePerRepresentativeChart,
      CrmStatistics.calculateTotal,
      this.statisticsService.getOrdersPerRepresentative(filter),
    );
  }

  private async loadOrdersPerCollectionChart(filter: IStatisticsFilter): Promise<void> {
    const settings = this.getChartSettings('ordersPerCollection');

    await this.loadChard(
      settings,
      CrmStatistics.populatePerCollectionChart,
      CrmStatistics.calculateTotal,
      this.statisticsService.getOrdersPerCollection(filter),
    );
  }

  // #endregion

  private async loadChard<T>(
    chartSettings: IChartSettings,
    populateCallback: CallableFunction,
    totalCallback: CallableFunction,
    source: Promise<T>,
  ): Promise<void> {
    const settings = chartSettings;

    settings.loading = true;
    try {
      const data = await source;
      populateCallback(settings, this.visualization, data, totalCallback);
    } catch (error) {
      settings.inError = true;
      throw error;
    } finally {
      settings.loading = false;
    }
  }

  public static populatePerAttendantChart(
    chartSettings: IChartSettings,
    visualization: StatisticsVisualizationEnum,
    data: StatisticsPerAttendant[],
    summaryCallback?: CallableFunction,
  ): void {
    const settings = chartSettings;
    settings.inError = false;

    settings.chartData = {
      labels: data.map((x) => x.atendente),
      datasets: [
        {
          data: data.map((x) => x.valor),
          backgroundColor: StatisticsService.getChartBackgroundColor(),
        },
      ],
    };

    const round = settings.summary && settings.summary[visualization].round;
    settings.summaryValue = summaryCallback ? summaryCallback(data, round) : 0;
  }

  public static populatePerRepresentativeChart(
    chartSettings: IChartSettings,
    visualization: StatisticsVisualizationEnum,
    data: StatisticsPerRepresentative[],
    summaryCallback?: CallableFunction,
  ): void {
    const settings = chartSettings;
    settings.inError = false;

    settings.chartData = {
      labels: data.map((x) => x.representante),
      datasets: [
        {
          data: data.map((x) => x.valor),
          backgroundColor: StatisticsService.getChartBackgroundColor(),
        },
      ],
    };

    const round = settings.summary && settings.summary[visualization].round;
    settings.summaryValue = summaryCallback ? summaryCallback(data, round) : 0;
  }

  public static populatePerCollectionChart(
    chartSettings: IChartSettings,
    visualization: StatisticsVisualizationEnum,
    data: StatisticsPerCollection[],
    summaryCallback?: CallableFunction,
  ): void {
    const settings = chartSettings;
    settings.inError = false;

    settings.chartData = {
      labels: data.map((x) => x.colecao),
      datasets: [
        {
          data: data.map((x) => x.valor),
          backgroundColor: StatisticsService.getChartBackgroundColor(),
        },
      ],
    };

    const round = settings.summary && settings.summary[visualization].round;
    settings.summaryValue = summaryCallback ? summaryCallback(data, round) : 0;
  }

  public static populatePerCityChart(
    chartSettings: IChartSettings,
    visualization: StatisticsVisualizationEnum,
    data: StatisticsPerCity[],
    summaryCallback?: CallableFunction,
  ): void {
    const settings = chartSettings;
    settings.inError = false;

    settings.chartData = {
      labels: data.map((x) => x.municipio),
      datasets: [
        {
          data: data.map((x) => x.valor),
          backgroundColor: StatisticsService.getChartBackgroundColor(),
        },
      ],
    };

    const round = settings.summary && settings.summary[visualization].round;
    settings.summaryValue = summaryCallback ? summaryCallback(data, round) : 0;
  }

  public static populatePerStateChart(
    chartSettings: IChartSettings,
    visualization: StatisticsVisualizationEnum,
    data: StatisticsPerCity[],
    summaryCallback?: CallableFunction,
  ): void {
    const settings = chartSettings;
    settings.inError = false;

    const groupedByState = groupBy(data, (x) => x.ufId);

    const stateData: IKeyValue<number> = {};

    Object.keys(groupedByState).forEach((key) => {
      const groups = groupedByState[key];
      const firstGroup = first(groups);
      let total = groups.reduce((remaining, obj) => remaining + obj.valor, 0);
      total = visualization === StatisticsVisualizationEnum.Quantity ? total / groups.length : total;

      if (firstGroup && firstGroup?.siglaEstado) {
        stateData[firstGroup.siglaEstado] = total;
      }
    });

    const sortedStateData = fromPairs(sortBy(toPairs(stateData), 1).reverse());

    settings.chartData = {
      labels: Object.keys(sortedStateData),
      datasets: [
        {
          data: Object.values(sortedStateData),
          backgroundColor: StatisticsService.getChartBackgroundColor(),
        },
      ],
    };

    const round = settings.summary && settings.summary[visualization].round;
    settings.summaryValue = summaryCallback ? summaryCallback(data, round) : 0;
  }

  public static calculateTotal(data: ICalcableStatistics[], round = true): number {
    if (!data?.length) {
      return 0;
    }

    const result = data.reduce((acc, item) => acc + (item.total || item.valor), 0);
    return round ? Math.round(result) : result;
  }

  public static calculateTotalAverage(data: ICalcableStatistics[], round = true): number {
    if (!data?.length) {
      return 0;
    }

    const result = data.reduce((acc, item) => acc + (item.total || item.valor), 0) / data.length;
    return round ? Math.round(result) : result;
  }

  public static calculateValueAverage(data: ICalcableStatistics[], round = true): number {
    if (!data?.length) {
      return 0;
    }

    const result = data.reduce((acc, item) => acc + (item.valor || 0), 0) / data.length;
    return round ? Math.round(result) : result;
  }

  private parseFilter(): IStatisticsFilter {
    let periodStart = dayjs(this.filters.period[0]);
    let periodEnd = dayjs(this.filters.period[1]);

    if (periodStart.diff(periodEnd, 'day') > 0) {
      periodStart = periodEnd;
      periodEnd = dayjs(this.filters.period[0]);
    }

    const parsedFilter: IStatisticsFilter = {
      periodStart: periodStart.toDate(),
      periodEnd: periodEnd.toDate(),
      visualization: this.visualization,
      considerUserWhoEnterOrder: this.considerUserWhoEnterOrder,
    };

    this.filters.period = [parsedFilter.periodStart, parsedFilter.periodEnd];

    if (this.filters.attendant?.length) {
      parsedFilter.attendant = this.filters.attendant;
    }

    if (this.filters.representative?.length) {
      parsedFilter.representative = this.filters.representative.map((x) => x.codigo);
    }

    if (this.filters.state) {
      parsedFilter.state = this.filters.state.sigla as string;
    }

    if (this.filters.city?.length) {
      parsedFilter.city = this.filters.city.map((x) => x.codIBGE);
    }

    if (this.filters.integratorUser?.length) {
      parsedFilter.integratorUser = this.filters.integratorUser;
    }

    if (this.filters.orderOrigin?.length) {
      parsedFilter.orderOrigin = this.filters.orderOrigin;
    }

    return parsedFilter;
  }

  private getChartSettings(key: string): IChartSettings {
    let chart = this.pieCharts.find((x) => x.key === key);

    if (!chart) {
      chart = CrmStatistics.generateChartSettings('unknown');
      this.pieCharts.push(chart);
    }

    return chart;
  }

  private getCardDetailsSettings(key: string): ICardDetails {
    let card = this.cardDetails.find((x) => x.key === key);

    if (!card) {
      card = {
        key: 'unknown',
        unit: '',
        value: 0,
        loading: false,
      };
      this.cardDetails.push(card);
    }

    return card;
  }

  private async loadFilterOptions(): Promise<void> {
    const tasks = [
      this.getStates().then((result) => {
        this.stateOptions = result;
      }),
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
      }),
      this.getRepresentatives().then((result) => {
        this.representativeOptions = result;
      }),
      this.getIntegratorUsers().then((result) => {
        this.integratorUserOptions = result;
      }),
      this.getOrderOrigins().then((result) => {
        this.orderOriginOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private static generateChartSettings(key: string, summary?: IKeyValue<IChartSettingsSummary>): IChartSettings {
    return {
      key,
      chartData: null,
      loading: false,
      summaryValue: 0,
      summary,
    };
  }

  private async getAttendants(): Promise<UserModel[]> {
    return this.activityService.getAttendants();
  }

  private async getRepresentatives(): Promise<RepresentativeModel[]> {
    return this.representativeService.getRepresentatives();
  }

  private async getIntegratorUsers(): Promise<DynamicFieldModel[]> {
    return this.orderService.getDynamicFieldValues(4);
  }

  private async getOrderOrigins(): Promise<DynamicFieldModel[]> {
    return this.orderService.getDynamicFieldValues(5);
  }

  private async getStates(): Promise<StateModel[]> {
    return sortBy(await this.localizationService.getStates(), 'nome');
  }

  private async getCities(uf: string): Promise<CityModel[]> {
    this.cityFilterLoading = true;

    const result = sortBy(await this.localizationService.getCities(uf), 'nome');

    this.cityFilterLoading = false;

    return result;
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }
}
